<template>
  <div class="app-parameters">
    <PageHeader
      title="Paramètres"
    />

    <PageContent>
      <div v-if="domain">

        <section class="shadow sm:overflow-hidden sm:rounded-md">
          <div class="flex items-start justify-between bg-white">
            <!-- infos -->
            <div class="w-1/2">
              <div class="col-span-2 p-6 md:col-span-1 flex flex-col gap-3">
                <div class="">
                  <label for="domainLabel" class="block text-sm font-medium leading-6 text-gray-900">
                    Nom du compte
                  </label>
                  <input
                    type="text"
                    name="label"
                    id="domainLabel"
                    class="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    v-model="domain.label"
                  />
                </div>

                <div>
                  <label for="domainHostnameAdd" class="block text-sm font-medium leading-6 text-gray-900">
                    Noms de domaine autorisés pour l'inscription libre
                  </label>
                  <div class="mt-2 flex rounded-md shadow-sm">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                      <input
                        type="text"
                        id="domainHostnameAdd"
                        class="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="exemple.com"
                        v-model="pattern"
                        @keyup.enter="addHostname"
                      />
                    </div>
                    <button
                      type="button"
                      class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset bg-primary-600 ring-primary-300 hover:bg-primary-700"
                      @click="addHostname"
                    >
                      Ajouter
                    </button>
                  </div>
                  <ul>
                    <li
                      v-for="(hostname, i) in domain.hostnames"
                      :key="i"
                      class="flex items-baseline gap-2"
                      >
                      <span @click="removeHostname(hostname.pattern)">
                        <i class="fas fa-times text-red-700 cursor-pointer" />
                      </span>
                      {{ hostname.pattern }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- preview -->
            <div
              class="px-4 py-6 sm:p-6"
              style="width: 390px">
              <div
                class="flex justify-between items-center p-4 rounded-t-2xl"
                :style="'background-color:' + domain.color"
              >
                <div class="w-1/6 h-full grow-0 shrink-0">
                  <img
                    v-if="domain.logo"
                    class="h-full"
                    :src="storageUrl + '/logos/' + domain.logo"
                  />
                </div>
                <div class="flex-2 grow text-center w-auto text-white text-lg leading-5">
                  {{ domain.label }}
                </div>
                <div class="w-1/6 grow-0 shrink-0 flex text-right">
                </div>
              </div>
              <div class="text-center text-sm text-gray-400 py-2">
                Aperçu du rendu sur mobile
              </div>

              <div class="flex p-5 justify-between">
                <t-input-group label="Couleur">
                  <v-swatches v-model="domain.color"
                    popover-y="bottom"
                    shapes="circles"
                    swatches="text-advanced"
                  />
                </t-input-group>
                <t-input-group label="Logo">
                  <b-upload @input="addLogo" accept="image/png, image/jpeg">
                    <div
                      class="rounded-full p-1 w-10 text-center"
                      :style="'background-color:'+domain.color+';'"
                    >
                      <img
                        v-if="domain.logo"
                        :src="storageUrl + '/logos/' + domain.logo"
                        class="h-8 w-8 block m-auto"
                        alt=""
                      />
                      <b-image v-else class="is-64x64" :src="logoMVP" alt="" />
                    </div>
                  </b-upload>
                </t-input-group>
              </div>
            </div>
          </div>

          <!-- modules -->
          <div class="bg-white px-6 pb-6">
            <fieldset
              class="clos-span-3 md:col-span-1"
              v-if="['admin','community-manager'].some(role => roles.includes(role))"
            >
              <legend class="text-base font-medium text-gray-900">
                Modules
              </legend>

              <div class="mt-4 grid grid-cols-7">
                <div
                  class="flex"
                  v-for="mod in modules"
                  :key="mod.slug"
                >
                  <label class="flex items-center">
                    <t-checkbox
                      :id="'module-' + mod.slug"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                      :checked="domain[mod.slug].is_activated"
                      @input="$event => handleModule($event, mod.slug)"
                    />
                    <span class="ml-3 text-sm">
                      {{ mod.title }}
                    </span>
                  </label>
                </div>

              </div>
            </fieldset>
          </div>

          <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              class="inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              @click="save"
            >
              Sauvegarder
            </button>
          </div>
        </section>

        <div
          v-if="domain.contact && domain.contact.is_activated"
          class="mt-6 mb-6 shadow sm:overflow-hidden sm:rounded-md"
        >
          <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Module Contact
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Informations de contact qui seront affichées dans l'application
              </p>
            </div>
            <div class="col-span-2 md:col-span-1 flex flex-col gap-3">
              <!-- <div class="">
                <label for="domainContactMessage" class="block text-sm font-medium leading-6 text-gray-900">
                  Message
                </label>
                <input
                  type="text"
                  id="domainContactMessage"
                  class="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                  v-model="domain.contact.message"
                />
              </div> -->
              <div class="grid grid-cols-2 gap-6">
                <div>
                  <label for="domainContactEmail" class="block text-sm font-medium leading-6 text-gray-900">
                    Adresse e-mail
                  </label>
                  <input
                    type="email"
                    id="domainContactEmail"
                    class="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    v-model="domain.contact.email"
                  />
                </div>
                <div>
                  <label for="domainContactPhone" class="block text-sm font-medium leading-6 text-gray-900">
                    Téléphone
                  </label>
                  <input
                    type="text"
                    id="domainContactPhone"
                    class="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    v-model="domain.contact.phone"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              class="inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              @click="updateContact"
            >
              Sauvegarder
            </button>
          </div>
        </div>

      </div>
    </PageContent>
  </div>
</template>

<script>
// import VueTimepicker from 'vue2-timepicker'
// import 'vue2-timepicker/dist/VueTimepicker.css'
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'
import VSwatches from 'vue-swatches'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent.vue'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'app-parameters',
  components: {
    PageHeader,
    PageContent,
    VSwatches // VueTimepicker,
  },
  computed: {
    ...mapGetters([
      'roles',
      'user'
    ]),
    domain: {
      get () {
        return this.$store.getters.domain
      },
      set (domain) {
        return domain
      }
    }
  },
  data () {
    return {
      logoUrl: null,
      logoMVP: '/logo.png',
      pattern: '',
      defaultColor: '#2E6FFB',
      modules: [
        {
          slug: 'faq',
          title: 'Foire aux questions'
        },
        {
          slug: 'chat',
          title: 'Chat'
        },
        {
          slug: 'news',
          title: 'Actualités'
        },
        {
          slug: 'payment',
          title: 'Paiement'
        },
        {
          slug: 'survey',
          title: 'Sondages'
        },
        {
          slug: 'document',
          title: 'Documents'
        },
        {
          slug: 'contact',
          title: 'Contact'
        }
      ],
      collapsed: {
        env: true,
        modules: true,
        management: true,
        chat: false,
        contact: false,
        faq: false
      },
      fileLogo: null
    }
  },
  created () {
    this.storageUrl = process.env.VUE_APP_STORAGE_URL
    if (this.domain.has_logo) {
      this.logoUrl = `${this.storageUrl}/logo/${this.domain.logo}`
    }
    if (!this.domain.color) {
      this.domain.color = this.defaultColor
    }
    // this.loadUserDomain()
  },
  methods: {
    handleModule (checked, slug) {
      this.domain[slug].is_activated = checked
      this.save()
    },
    save () {
      http.put(`/domain/${this.domain.uuid}`, { ...this.domain }).then(({ data }) => {
        this.$store.commit('SET_DOMAIN', data)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Paramètres mis à jour avec succès',
          position: 'is-top-right',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    saveChatConfig () {
      http.put(`/domain/${this.domain.uuid}/chats`, { ...this.domain.chat }).then(({ data }) => {
        this.$store.commit('SET_DOMAIN', { chat: data, ...this.domain })
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Paramètres mis à jour avec succès',
          position: 'is-top-right',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    deleteDomain () {
      this.$buefy.dialog.confirm({
        title: 'Suppression',
        message: `Êtes vous sur de vouloir supprimer ce domaine ?`,
        cancelText: 'Annuler',
        confirmText: 'Confirmer',
        type: 'is-danger',
        onConfirm: () => {
          http.delete(`/domain/${this.domain.uuid}`).then(res => {
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Le domaine a été supprimé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
            this.$emit('removeDomain', this.domain)
          }).catch(err => {
            console.log(err)
            this.$buefy.notification.open({
              duration: 3000,
              message: 'Nos serveurs rencontrent des difficultés à supprimer ce domaine',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          })
        }
      })
    },
    addHostname () {
      http.post(`domain/${this.domain.uuid}/hostname`, { pattern: this.pattern }).then(res => {
        this.domain.hostnames = res.data
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.pattern = ''
      })
    },
    removeHostname (pattern) {
      http.delete(`domain/${this.domain.uuid}/hostname`, { data: { pattern } }).then(res => {
        this.domain.hostnames = res.data
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.pattern = ''
      })
    },
    // loadUserDomain () {
    //   http.get('/me/domain').then(res => {
    //     this.settings.name = res.data.label
    //     this.settings.messages = res.data.messages
    //     this.settings.timetable = res.data.timetable
    //   })
    // },
    updateParameters () {
      http.put(`/domain/${this.domain.uuid}/settings`, { ...this.settings, name: this.domain.label })
        .then(res => {
          this.$buefy.notification.open({
            duration: 3000,
            message: 'Paramètres sauvegardés !',
            position: 'is-bottom-left',
            type: 'is-success',
            hasIcon: true
          })
          this.domain = res.data
        })
        .catch(err => {
          console.log(err)
          this.$buefy.notification.open({
            duration: 3500,
            message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce domaine',
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        })
    },
    addLogo (file) {
      const formData = new FormData()
      formData.append('logo', file)
      http.post(`/domain/${this.domain.uuid}/logo`, formData).then(({ data }) => {
        this.domain.logo = data.logo
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le logo du domaine a bien été uploadé',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour le logo du domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    updateContact () {
      http.put(`/domain/${this.domain.uuid}`, { ...this.domain }).then(({ data }) => {
        this.$store.commit('SET_DOMAIN', data)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le domaine a été mis à jour avec succès !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 4px;
}

.module {
  margin-top: 20px;
}
.planning {
  margin-top: 20px;

  li {
    margin: 10px;
    display: flex;
  }
}
.fixed-hero {
  padding-top: 150px;
  .hero {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    margin: 0 260px;
  }
}
.column-timetable {
  display: flex;
  align-items: center;

  .timetable-label {
    flex-grow: 1;
  }

  .b-clockpicker {
    width: 90px;
  }
}

.preview {
  padding: 0 1rem;
  position: relative;
  .label {
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    font-weight: normal;
    font-size: .9rem;
    padding-top: 4px;
  }
  .preview-action {
    position: absolute;
    right: 1rem;
    top: -1rem;
    background: #fff;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
    }
  }
  h3 {
    font-size: 18px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .preview-right {
    width: 64px;
  }
}

.collapser {
  cursor: pointer;

  h3 {
    margin: 20px 0 10px 0;
  }
}
</style>
